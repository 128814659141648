import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Utils
import { SECTION_MARGIN } from '../utils/constants'
// Types
import { PageContextProps, PageInfoProps, PublicationProps } from '../types/pages'
// Components
import { ContentMd, Layout, Seo } from '../components/commons'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Box, Heading, Text } from '../atoms'
import { SECTION_BOTTOM_PADDING, SECTION_TOP_PADDING } from '../utils/constants'
import { StaticImage } from 'gatsby-plugin-image'

interface LegalQuery {
  legal: {
    pageInfo: PageInfoProps
    meta: PublicationProps
    headline: string
    lastUpdatedLabel: string
    updatedDate: string
    content: string
  }
}

const Legal: React.FC<PageProps<LegalQuery, PageContextProps>> = ({ data, pageContext }) => {
  const {
    legal: { meta, pageInfo, headline, lastUpdatedLabel, updatedDate, content },
  } = data

  return (
    <Layout
      pageId={pageContext.id}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutHeader={pageInfo.hideHeader}
      withoutPrefooter={pageInfo.hidePrefooter}
      withoutFooter={pageInfo.hideFooter}
    >
      <Seo
        slug={pageInfo.slug}
        canonicalUrl={pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={meta}
        content={pageInfo.seo}
        preventIndexing={pageInfo.preventIndexing}
      />
      <Box as={'section'} backgroundColor={'violetLight'} overflow={'hidden'}>
        <Grid>
          <Box position={'relative'} pt={SECTION_TOP_PADDING} pb={SECTION_BOTTOM_PADDING}>
            <Box display={['none', 'none', 'block']} position={'absolute'} top={-190} left={-280} zIndex={0}>
              <StaticImage
                formats={['auto']}
                draggable={false}
                src={'../images/ellipse_violet_tbr.png'}
                alt={''}
                placeholder={'none'}
                width={436}
              />
            </Box>
            <Box display={['none', 'none', 'block']} position={'absolute'} bottom={-220} right={-320} zIndex={0}>
              <StaticImage
                formats={['auto']}
                draggable={false}
                src={'../images/ellipse_violet_grid.png'}
                alt={''}
                placeholder={'none'}
                width={470}
              />
            </Box>
            <Box position={'relative'} zIndex={1}>
              <Row center={'xs'}>
                <Col xs={12} md={8}>
                  <Heading as={'h1'} type={'display'} textAlign={['left', 'left', 'center']}>
                    {headline}
                  </Heading>
                  {updatedDate && (
                    <Box mt={5}>
                      <Text
                        type={'large'}
                        textAlign={['left', 'left', 'center']}
                      >{`${lastUpdatedLabel}: ${updatedDate}`}</Text>
                    </Box>
                  )}
                </Col>
              </Row>
            </Box>
          </Box>
        </Grid>
      </Box>
      <Box mt={[7, 7, 80]} mb={SECTION_MARGIN}>
        <Grid>
          <Row center={'xs'}>
            <Col xs={12} md={8}>
              <ContentMd content={content} />
            </Col>
          </Row>
        </Grid>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query LegalQuery($id: String!, $locale: String!) {
    legal: datoCmsLegal(locale: $locale, id: { eq: $id }) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      headline
      lastUpdatedLabel
      updatedDate(formatString: "LL")
      content
    }
  }
`

export default Legal

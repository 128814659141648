import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { graphql, PageProps } from 'gatsby'
import { PageContextProps, PageInfoProps, PublicationProps } from '../../types/pages'
import { SectionBase, SectionLogoList, SectionPush } from '../../types/sections'
import { IIntegrationCard } from '../../types/resources'
import { ButtonProps } from '../../types/buttons'
import { Layout, Seo } from '../../components/commons'
import { Box } from '../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { SECTION_MARGIN } from '../../utils/constants'
import Section from '../../components/sections'
import { ResourcesFilters, ResourcesHeroWithSearch, ResourcesResult } from '../../components/resources/commons'
import { ResourceContextProvider } from '../../contexts/ResourceContext'

interface IntegrationsQuery {
  landing: {
    meta: PublicationProps
    pageInfo: PageInfoProps
    preHeadline: string
    headline: string
    subHeadline: string
    searchPlaceholder: string
    filterTypeLabel: string
    filterAllLabel: string
    filterTagLabel: string
    featuredLabel: string
    noResultLabel: string
    noResultButton: Array<ButtonProps>
    featuredIntegrations: Array<IIntegrationCard>
    sections: Array<SectionPush | SectionLogoList>
  }
  integrations: {
    nodes: Array<IIntegrationCard>
  }
  tags: {
    nodes: Array<{
      id: string
      name: string
    }>
  }
}

const Integrations: React.FC<PageProps<IntegrationsQuery, PageContextProps>> = ({ data, pageContext }) => {
  const { landing, integrations, tags } = data

  const location = useLocation()

  const [defaultType, setDefaultType] = useState<'source' | 'destination' | undefined>(undefined)

  useEffect(() => {
    const type = new URLSearchParams(location.search).get('type')

    if (type && (type === 'source' || type === 'destination')) {
      setDefaultType(type)
    } else {
      setDefaultType(undefined)
    }
  }, [location, setDefaultType])

  return (
    <Layout
      pageId={'id'}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={landing.pageInfo.hidePrefooter}
    >
      <Seo
        slug={landing.pageInfo.slug}
        canonicalUrl={landing.pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={landing.meta}
        content={landing.pageInfo.seo}
        preventIndexing={landing.pageInfo.preventIndexing}
      />

      <ResourceContextProvider items={integrations.nodes} defaultType={defaultType}>
        <ResourcesHeroWithSearch
          preHeadline={landing.preHeadline}
          headline={landing.headline}
          subHeadline={landing.subHeadline}
          searchPlaceholder={landing.searchPlaceholder}
        />

        <Box my={SECTION_MARGIN}>
          <Grid>
            <Row>
              <Col xs={12} md={3}>
                <Box position={'sticky'} top={'calc(90px + var(--banner-height))'}>
                  <ResourcesFilters
                    filters={[
                      {
                        id: 'type',
                        label: landing.filterTypeLabel,
                        options: [
                          { label: landing.filterAllLabel, value: 'all' },
                          { label: 'Destinations', value: 'destination' },
                          { label: 'Sources', value: 'source' },
                        ],
                      },
                      {
                        id: 'tag',
                        label: landing.filterTagLabel,
                        options: [
                          { label: landing.filterAllLabel, value: 'all' },
                          ...tags.nodes.map((t) => ({ label: t.name, value: t.id })),
                        ],
                      },
                    ]}
                  />
                </Box>
              </Col>
              <Col xs={12} md={9}>
                <ResourcesResult
                  itemsPerRow={3}
                  featuredLabel={landing.featuredLabel}
                  featured={landing.featuredIntegrations}
                  noResultLabel={landing.noResultLabel}
                  noResultButton={landing.noResultButton}
                />
              </Col>
            </Row>
          </Grid>
        </Box>

        {landing.sections.map((s: SectionBase) => s?.model && <Section key={s.id} {...s} />)}
      </ResourceContextProvider>
    </Layout>
  )
}

export const query = graphql`
  query IntegrationsQuery($locale: String!, $integrationsTags: [String!]!) {
    landing: datoCmsResourcesGlobal(locale: $locale) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }

      pageInfo: integrationsPageInfo {
        ...pageInfoFragment
      }
      preHeadline: integrationsPreHeadline
      headline: integrationsHeadline
      subHeadline: integrationsSubHeadline
      searchPlaceholder: integrationsSearchPlaceholder
      filterAllLabel
      filterTypeLabel: integrationsFilterTypeLabel
      filterTagLabel: integrationsFilterTagLabel
      noResultLabel: integrationsNoResultLabel
      noResultButton: integrationsNoResultButton {
        ...buttonFragment
      }
      featuredLabel
      featuredIntegrations {
        ...integrationCardFragment
      }
      sections: integrationsSections {
        ...sectionPushFragment
        ...sectionLogoListFragment
      }
    }
    tags: allDatoCmsResourceTag(
      locale: $locale
      filter: { id: { in: $integrationsTags } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        name
      }
    }
    integrations: allDatoCmsIntegration(locale: $locale, sort: { order: ASC, fields: position }) {
      nodes {
        model {
          apiKey
        }
        ...integrationCardFragment
      }
    }
  }
`

export default Integrations

import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Types
import { PageContextProps, PageInfoProps, PublicationProps } from '../types/pages'
import { SectionBase } from '../types/sections'
// Components
import { Layout, Seo } from '../components/commons'
import Section from '../components/sections'

interface PricingQuery {
  pricing: {
    pageInfo: PageInfoProps
    meta: PublicationProps
    sections: SectionBase[]
  }
}

const Pricing: React.FC<PageProps<PricingQuery, PageContextProps>> = ({ data, pageContext }) => {
  const {
    pricing: { meta, pageInfo, sections },
  } = data

  return (
    <Layout
      pageId={pageContext.id}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutHeader={pageInfo.hideHeader}
      withoutPrefooter={pageInfo.hidePrefooter}
      withoutFooter={pageInfo.hideFooter}
    >
      <Seo
        slug={pageInfo.slug}
        canonicalUrl={pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={meta}
        content={pageInfo.seo}
        preventIndexing={pageInfo.preventIndexing}
      />
      {sections.map((s: SectionBase) => s?.model && <Section key={s.id} {...s} />)}
    </Layout>
  )
}

export const query = graphql`
  query PricingQuery($locale: String!) {
    pricing: datoCmsPricing(locale: $locale) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      sections {
        ...sectionStandardHeroFragment
        ...sectionFeatureSimpleFragment
        ...sectionLogoListFragment
        ...sectionFAQFragment
        ...sectionPricingFragment
      }
    }
  }
`

export default Pricing
